import * as React from "react";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Profile from "./images/profile.jpg";
import LotusBookFolding from "./images/LotusBookFolding.png";
import ForeEdgeBookArt from "./images/ForeEdgeBookArt.png";
import ShuttleBuddy from "./images/ShuttleBuddy.png";

const VerticalContainer = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;
const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;
`;

const App = () => {
  return (
    <VerticalContainer>
      <Avatar
        alt="Matt Lang"
        src={Profile}
        sx={{
          width: 250,
          height: 250,
          border: "2px solid #7E868D",
        }}
      />

      <Typography variant="h2" style={{ textAlign: "center" }}>
        Matt Lang
      </Typography>

      <HorizontalContainer>
        <IconButton
          aria-label="LinkedIn"
          color="primary"
          size="large"
          href="https://www.linkedin.com/in/m-lang/"
        >
          <LinkedInIcon fontSize="inherit" />
        </IconButton>
      </HorizontalContainer>

      <hr style={{ width: "70vw", borderColor: "#7E868D" }} />

      <HorizontalContainer>
        <ProjectIcon
          image={LotusBookFolding}
          name="Lotus Book Folding"
          link="https://lotusbookfolding.com/"
        />
        <ProjectIcon
          image={ForeEdgeBookArt}
          name="Fore Edge Book Art"
          link="https://foreedgebookart.com/"
        />
      </HorizontalContainer>
    </VerticalContainer>
  );
};

const ProjectIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 25vw;
`;

const ProjectIcon = ({ image, name, link }) => {
  return (
    <IconButton aria-label={name} size="large" href={link}>
      <ProjectIconContainer>
        <Avatar
          alt={name}
          src={image}
          sx={{
            width: 75,
            height: 75,
          }}
        />
        <Typography
          variant="subtitle1"
          color={"#E9F1F7"}
          style={{ maxWidth: "inherit" }}
        >
          {name}
        </Typography>
      </ProjectIconContainer>
    </IconButton>
  );
};

export default App;
